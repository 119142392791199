import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
// import { Link } from 'gatsby'
import TransitionLink, { TransitionState } from 'gatsby-plugin-transition-link'
import classNames from 'classnames/bind'
import Seo from '../components/seo'
import Logo from '../components/logo'
import * as indexStyles from '../styles/modules/index.module.scss'

const exitTransition = {
  length: 0.4, // Take x seconds to leave
  // trigger: () => console.log('We are exiting'),
}

const entryTransition = {
  delay: 0.4, // Wait x seconds before entering
  // trigger: () => console.log('We are entering'),
}

const cx = classNames.bind(indexStyles)

const Home = () => (
  <TransitionState>
    {({ transitionStatus }) => (
      <>
        <Seo title="Hello!" />
        <div className={cx({ landingFlex: true, exit: transitionStatus === 'exiting' })}>
          <div className={indexStyles.cover}>
            <Logo width={350} />
            <h1>Kyah Morrissette</h1>
            <div className={indexStyles.tagline}>
              <b>Psychic Intuitive</b>
            </div>
          </div>
          <div className={indexStyles.middleText}>
            <div />
            <p>Hello! I’m so glad you’re here!</p>
            <TransitionLink
              className={indexStyles.button}
              to="/about/"
              exit={exitTransition}
              entry={entryTransition}
            >
              What can I do for you?
            </TransitionLink>
            <div />
          </div>
          <div className={indexStyles.images}>
            <StaticImage
              src="../images/Kyah-Morrissette.jpeg"
              alt="Headshot of Kyah Morrissette"
              layout="constrained"
              width={400}
              aspectRatio={1 / 1}
              className={indexStyles.headshot}
            />
            <StaticImage
              src="../images/mr-felix-says-cards.jpeg"
              alt=""
              layout="constrained"
              width={225}
              aspectRatio={1 / 1}
              className={indexStyles.littleImage}
            />
          </div>
        </div>
      </>
    )}
  </TransitionState>
)

export default Home
