// extracted by mini-css-extract-plugin
export var activeLayout = "index-module--active-layout--1EeKn";
export var sidebarImage = "index-module--sidebar-image--Ozhnu";
export var image = "index-module--image--1H0O6";
export var block = "index-module--block--2-EpG";
export var landingFlex = "index-module--landingFlex--1UnNi";
export var tagline = "index-module--tagline--2LrIQ";
export var exit = "index-module--exit--LT6qy";
export var middleText = "index-module--middleText--2_xkF";
export var images = "index-module--images--G4JsJ";
export var headshot = "index-module--headshot--1Vumf";
export var littleImage = "index-module--littleImage--25tAh";
export var button = "index-module--button--Khn38";
export var cover = "index-module--cover--21FHX";